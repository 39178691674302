import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  new Swiper('.apartments__slider .swiper', {
    loop: true,
    spaceBetween: 20,
    loopedSlides: 10,

    navigation: {
      nextEl: '.apartments__next',
      prevEl: '.apartments__prev',
    },

    // pagination: {
    //   el: '.screen__pagination',
    //   clickable: true,
    // },

    breakpoints: {
      // 768: {
      //   slidesPerView: 2,
      //   spaceBetween: 30,
      // },
      1200: {
        // slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  });
};

const initZoom = () => {
  const galleries = document.querySelectorAll<HTMLDivElement>(
    '.apartments__gallery'
  );
  galleries.forEach((gallery) => {
    const anchors = gallery.querySelectorAll<HTMLAnchorElement>('a');

    const items = Array.from(anchors).map((item) => ({
      href: item.getAttribute('href'),
    }));

    const glightbox = GLightbox({
      elements: items,
      loop: true,
    });

    anchors.forEach((anchor, idx) => {
      anchor.addEventListener('click', (event) => {
        event.preventDefault();
        glightbox.openAt(idx);
      });
    });

    // const id = slider.dataset.id;
    // GLightbox({
    //   selector: `.rooms__slider[data-id="${id}"] .swiper-slide:not(.swiper-slide-duplicate) .rooms__slide`,
    //   loop: true,
    // });
  });
};

const apartments = {
  init: () => {
    initSlider();
    initZoom();
  },
};

export default apartments;
