// import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';

// Swiper.use([Navigation, Pagination]);

// const initSlider = () => {
//   new Swiper('.gallery__slider .swiper', {
//     loop: true,
//     spaceBetween: 0,

//     navigation: {
//       nextEl: '.gallery__next',
//       prevEl: '.gallery__prev',
//     },

//     // pagination: {
//     //   el: '.gallery__pagination',
//     //   clickable: true,
//     // },

//     // breakpoints: {
//     //   768: {
//     //     slidesPerView: 2,
//     //     spaceBetween: 30,
//     //   },
//     //   1200: {
//     //     slidesPerView: 4,
//     //     spaceBetween: 30,
//     //   },
//     // },
//   });
// };

const initZoom = () => {
  // GLightbox({
  //   selector: '.gallery__slide',
  //   loop: true,
  // });

  GLightbox({
    selector: '.gallery__content a',
    loop: true,
  });
};

const gallery = {
  init: () => {
    // initSlider();
    initZoom();
  },
};

export default gallery;
