import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { stockReducer } from 'features/stock/stock.slice';
import { addReviewReducer } from '../features/addReview/addReview.slice';
import { calculationReducer } from '../features/calculation/calculation.slice';
import { callbackReducer } from '../features/callback/callback.slice';
import { commonReducer } from '../features/common/common.slice';
import { questionReducer } from '../features/question/question.slice';
import { quizReducer } from '../features/quiz/quiz.slice';
import { reviewReducer } from '../features/review/review.slice';
import { serviceReducer } from '../features/service/service.slice';

export const store = configureStore({
  reducer: {
    common: commonReducer,
    callback: callbackReducer,
    calculation: calculationReducer,
    service: serviceReducer,
    stock: stockReducer,
    review: reviewReducer,
    addReview: addReviewReducer,
    question: questionReducer,
    quiz: quizReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
