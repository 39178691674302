import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import GLightbox from 'glightbox';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { closeService, selectService } from '../service.slice';
import styles from './serviceBody.module.scss';
import { useEffect } from 'react';
import { CloseButton } from 'components/elements/closeButton/CloseButton';

SwiperCore.use([Navigation]);

export const ServiceBody = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const service = useAppSelector(selectService);

  useEffect(() => {
    const glightbox = GLightbox({
      selector: '.service-slide-glighbox',
    });
    return () => glightbox.destroy();
  });

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeService())}
      />

      <div className={styles.slider}>
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          navigation={{
            prevEl: '.service-swiper-nav-prev',
            nextEl: '.service-swiper-nav-next',
          }}
          pagination={{
            el: '.service-swiper-pagination',
            clickable: true,
          }}
        >
          {service.images.map((image, idx) => (
            <SwiperSlide key={idx}>
              <a
                className={cn(styles.slide, 'service-slide-glighbox')}
                href={image}
              >
                <img src={image} alt="service" />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className={cn(styles.nav, 'service-swiper-nav')}>
          <button className={cn(styles.prev, 'service-swiper-nav-prev')} />
          <button className={cn(styles.next, 'service-swiper-nav-next')} />
        </div>

        <div className={cn(styles.pagination, 'service-swiper-pagination')} />
      </div>

      <div className={styles.block}>
        <p className={styles.name}>{service.name}</p>
        <p
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: service.text }}
        />
      </div>
    </div>
  );
};
