import { useAppDispatch, useAppSelector } from 'app/hooks';
import { openThanks } from 'features/common/common.slice';
import { FormEventHandler } from 'react';
import { clearQuiz, selectQuiz } from './quiz.slice';
import styles from './quiz.module.scss';
import { Slider } from 'components/layout/slider/Slider';
import { QuizControl } from './quizControl/QuizControl';
import { QuizOne } from './quizOne/QuizOne';
import { QuizTwo } from './quizTwo/QuizTwo';
import { QuizThree } from './quizThree/QuizThree';

declare const ym: any;
// declare const gtag: any;

export const Quiz = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const quiz = useAppSelector(selectQuiz);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    if (!quiz.arrival || !quiz.departure) {
      return;
    }

    ym(87418862, 'reachGoal', 'kick');
    // gtag('event', 'submit', { event_category: 'form' });

    setTimeout(() => dispatch(openThanks('request')), 300);

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'Расчет стоимости',
        arrival: new Date(quiz.arrival).toLocaleDateString(),
        departure: new Date(quiz.departure).toLocaleDateString(),
        adults: quiz.adults,
        babies: quiz.babies,
        name: quiz.name,
        phone: quiz.phone,
      }),
    });

    dispatch(clearQuiz());
  };

  return (
    <div className={styles.module}>
      {/* <p className={styles.steps}>Шаг {quiz.step} из 3</p> */}
      <form className={styles.form} onSubmit={handleSubmit}>
        <Slider className={styles.slider} offsetBy={quiz.step - 1}>
          <QuizOne />
          <QuizTwo />
          <QuizThree />
        </Slider>
        <QuizControl />
      </form>
    </div>
  );
};
