import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
// import GLightbox from 'glightbox';

Swiper.use([Navigation, Pagination, Autoplay]);

const initSlider = () => {
  new Swiper('.screen__slider .swiper', {
    loop: true,
    spaceBetween: 0,
    autoplay: {
      delay: 3000,
    },

    // navigation: {
    //   nextEl: '.screen__next',
    //   prevEl: '.screen__prev',
    // },

    pagination: {
      el: '.screen__pagination',
      clickable: true,
    },

    // breakpoints: {
    //   768: {
    //     slidesPerView: 2,
    //     spaceBetween: 30,
    //   },
    //   1200: {
    //     slidesPerView: 4,
    //     spaceBetween: 30,
    //   },
    // },
  });
};

// const initZoom = () => {
//   GLightbox({
//     selector: '.about__slide',
//     loop: true,
//   });
// };

const screen = {
  init: () => {
    initSlider();
    // initZoom();
  },
};

export default screen;
