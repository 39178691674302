import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { StockData, StockState } from './stock.types';

const initialState: StockState = {
  image: '',
  name: '',
  text: '',
  ui: {
    stockOpened: false,
  },
};

export const stockSlice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    openStock: (state, { payload }: PayloadAction<StockData>) => {
      state.image = payload.image;
      state.name = payload.name;
      state.text = payload.text;

      state.ui.stockOpened = true;
    },
    closeStock: (state) => {
      state.ui.stockOpened = false;
    },
  },
});

export const { openStock, closeStock } = stockSlice.actions;

export const selectStock = (state: RootState) => state.stock;

export const stockReducer = stockSlice.reducer;
