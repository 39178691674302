import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CloseButton } from 'components/elements/closeButton/CloseButton';
import { closeReview, selectReview } from '../review.slice';
import styles from './reviewBody.module.scss';

export const ReviewBody = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const review = useAppSelector(selectReview);

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeReview())}
      />

      <div className={styles.top}>
        <p className={styles.name}>{review.name}</p>

        <p className={styles.block}>
          <span className={styles.grade}>
            <em>Оценка</em> <strong>{review.grade}</strong> <span>из 10</span>
          </span>

          <span className={styles.date}>{review.date}</span>
        </p>
      </div>
      <div className={styles.bottom}>
        <p className={styles.text}>{review.text}</p>
      </div>
    </div>
  );
};
