import { useAppDispatch, useAppSelector } from 'app/hooks';
import { closeStock, selectStock } from '../stock.slice';
import { CloseButton } from 'components/elements/closeButton/CloseButton';
import styles from './stockBody.module.scss';
import { openCallback } from 'features/callback/callback.slice';

export const StockBody = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const stock = useAppSelector(selectStock);

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeStock())}
      />

      <div className={styles.image}>
        <img src={stock.image} alt={stock.name} />
      </div>

      <p className={styles.name}>{stock.name}</p>

      <p
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: stock.text }}
      />

      <button
        className={styles.button}
        onClick={() => dispatch(openCallback())}
      >
        Получить консультацию
      </button>
    </div>
  );
};
