import { useAppDispatch, useAppSelector } from 'app/hooks';
import { closeStock, selectStock } from './stock.slice';
import { Modal } from 'components/layout/modal/Modal';
import { Portal } from 'components/other/portal/Portal';
import { Transition } from 'components/other/transition/Transition';
import { StockBody } from './stockBody/StockBody';

export const Stock = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const stock = useAppSelector(selectStock);

  return (
    <Transition active={stock.ui.stockOpened} timeout={300}>
      {(transitionStyles) => (
        <Portal blocked>
          <Modal
            className={transitionStyles}
            onOverlayClick={() => dispatch(closeStock())}
          >
            <StockBody />
          </Modal>
        </Portal>
      )}
    </Transition>
  );
};
