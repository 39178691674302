import { useEffect } from 'react';
import { useAppDispatch } from 'app/hooks';
import { Cookie } from 'components/elements/cookie/Cookie';
import { AddReview } from 'features/addReview/AddReview';
import { Calculation } from 'features/calculation/Calculation';
import { openCalculation } from 'features/calculation/calculation.slice';
import { Callback } from 'features/callback/Callback';
import { TextModal } from 'features/common/textModal/TextModal';
import { Thanks } from 'features/common/thanks/Thanks';
import { Review } from 'features/review/Review';
import { Service } from 'features/service/Service';
import { openFromReact } from 'utils/helpers/browser.helpers';
import { openService } from 'features/service/service.slice';
import { openStock } from 'features/stock/stock.slice';
import { Stock } from 'features/stock/Stock';
import { openCallback } from 'features/callback/callback.slice';
import { openReview } from 'features/review/review.slice';
import { openAddReview } from 'features/addReview/addReview.slice';
import { openTextModal } from 'features/common/common.slice';

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    openFromReact('.header__callback', () => dispatch(openCallback()));
    openFromReact('.screen__calculation', () => dispatch(openCalculation()));
    openFromReact('.services__button', ({ currentTarget }) => {
      if (currentTarget instanceof HTMLElement) {
        dispatch(
          openService({
            name: currentTarget.dataset.name ?? '',
            text: currentTarget.dataset.text ?? '',
            images: currentTarget.dataset.images?.split(',') ?? [],
          })
        );
      }
    });
    openFromReact('.stocks__slide div button', ({ currentTarget }) => {
      if (currentTarget instanceof HTMLElement) {
        dispatch(
          openStock({
            name: currentTarget.dataset.name ?? '',
            text: currentTarget.dataset.text ?? '',
            image: currentTarget.dataset.image ?? '',
          })
        );
      }
    });
    openFromReact('.stocks__item div button', ({ currentTarget }) => {
      if (currentTarget instanceof HTMLElement) {
        dispatch(
          openStock({
            name: currentTarget.dataset.name ?? '',
            text: currentTarget.dataset.text ?? '',
            image: currentTarget.dataset.image ?? '',
          })
        );
      }
    });
    openFromReact('.rooms__calculation', ({ currentTarget }) => {
      if (currentTarget instanceof HTMLElement) {
        dispatch(openCalculation(currentTarget.dataset.name));
      }
    });
    openFromReact('.apartments__calculation', ({ currentTarget }) => {
      if (currentTarget instanceof HTMLElement) {
        dispatch(openCalculation(currentTarget.dataset.name));
      }
    });
    openFromReact('.question__button', () => dispatch(openCallback()));
    openFromReact('.reviews__button', ({ currentTarget }) => {
      if (currentTarget instanceof HTMLElement) {
        dispatch(
          openReview({
            name: currentTarget.dataset.name ?? '',
            grade: currentTarget.dataset.grade ?? '',
            date: currentTarget.dataset.date ?? '',
            text: currentTarget.dataset.text ?? '',
          })
        );
      }
    });
    openFromReact('.reviews__add', () => dispatch(openAddReview()));
    openFromReact('.footer__callback', () => dispatch(openCallback()));
    openFromReact('#footer-policy', () => dispatch(openTextModal('policy')));
    openFromReact('#footer-rules', () => dispatch(openTextModal('rules')));
    openFromReact('#footer-cookie', () => dispatch(openTextModal('cookie')));
    openFromReact('#footer-residence', () =>
      dispatch(openTextModal('residence'))
    );
    // openFromReact('#fee-cookie', () => dispatch(openTextModal('fee')));
  }, [dispatch]);

  return (
    <>
      <Callback />
      <Calculation />
      <Service />
      <Stock />
      <Review />
      <AddReview />

      <TextModal />
      <Thanks />
      <Cookie />
    </>
  );
}

export default App;
